<template>
  <span class="inline-block">
  <a v-if="testLink == 'htt'" target="_blank" :href="destination" :class="[cls, (marque != undefined ? marque : 'leasys')]">
    <div><slot></slot></div>
  </a>
  <router-link v-else :to="Go" :class="[cls, (marque != undefined ? marque : 'leasys')]">
    <div><slot></slot></div>
  </router-link>
  </span>
</template>

<script>
export default {
  props: {
    destination: String,
    cls: String,
    marque: String
  },
  name: 'Cta',
  computed: {
    testLink: function(){
      if(this.destination != undefined){
        return this.destination.substr(0,3)
      }else{
        return this.destination
      }
    },
    Go: function(){
      if(this.destination != undefined){
        return this.destination
      }else{
        return '/form'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn1,.btn2{
    & > div{
      @apply rounded;
    }
  }
  .btn1{
    & > div{
      @apply py-3 px-5 text-center inline-block transition-all duration-300 ease-out;
      background: var(--cta-1-color);
      color: var(--cta-1-text)
    }
    &:hover > div{
      background-color: var(--cta-1-hover);
    }
  }
  .btn2{
    & > div{
      @apply py-3 px-5 text-center border border-gray-300 inline-block transition-all duration-300 ease-out;
      background: var(--cta-2-color);
      color: var(--cta-2-hover)
    }
    &:hover > div{
      background-color: var(--cta-2-hover);
      color: var(--cta-2-color);
      border-color: var(--cta-2-hover);
    }
  }
  .btn3{
    & > div{
      @apply py-3 px-8 border-2 border-white rounded-md text-center inline-block transition-all duration-300 ease-out;
      background: var(--cta-3-color);
      color: var(--cta-3-text)
    }
    &:hover > div{
      background-color: var(--cta-3-hover);
    }
  }
  .btn3.outline {
    & > div{
      @apply py-3 px-8 bg-transparent border-2 rounded-md text-center inline-block transition-all duration-300 ease-out;
      border-color: var(--cta-3-color);
      color: var(--cta-3-color)
    }
    &:hover > div{
      background-color: var(--cta-3-hover);
      color: var(--cta-3-text)
    }
  }
</style>