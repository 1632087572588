<template>
  <div class="footer text-white" :class="marque">
    <div class="w-full relative py-8 px-4">
      <section class="container flex mx-auto justify-center relative">
        <div class="w-full md:w-8/12">
          <slot></slot>
        </div>
      </section>
    </div>
    <div class="w-full relative py-6 px-4">
      <section class="container flex mx-auto justify-center">
        <div class="w-full md:w-8/12 text-xs">
          <p><a href="https://www.fca-motorvillage.fr/distributeur/fiat/Politique-de-confidentialite" class="underline" target="_blank">Politique de confidentialité</a></p>
          <p>FCA MOTOR VILLAGE France. 2/10 Boulevard de l’Europe, Pôle Tertiaire 1, 78300&nbsp;Poissy. SA au Capital de 2&nbsp;977&nbsp;680&nbsp;€. SIRET 552&nbsp;025&nbsp;181&nbsp;00165. RCS B&nbsp;552&nbsp;025&nbsp;181. TVA Intracommunautaire FR84&nbsp;552&nbsp;025&nbsp;18</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    marque: String
  },
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
.footer{
  text-align: center;
  & > div:first-child{
    background-color: var(--primary);
  }
  & > div:last-child{
    background-color: var(--color6);
  }
}
</style>