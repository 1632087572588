<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default{
    data(){
      return{
        utm: {
          utmSource: 'DIRECT',
          utmCampaign: 'SAY_BOUL_FIAT500E',
          utmMedium: 'DIRECT',
          utmContent: 'FIAT500E_V2_PO12juin',
        }
      }
    },
    methods: {
      param() {
        let params = {};
        window.location.search.slice(1).split('&').forEach(elm => {
          if (elm === '') return;
          let spl = elm.split('=');
          const d = decodeURIComponent;
          params[d(spl[0])] = (spl.length >= 2 ? d(spl[1]) : true);
        });
        params['utm_source'] != null ? this.utm.utmSource = params['utm_source'] : this.utm.utmSource = this.utm.utmSource
        params['utm_campaign'] != null ? this.utm.utmCampaign = params['utm_campaign'] : this.utm.utmCampaign = this.utm.utmCampaign
        params['utm_medium'] != null ? this.utm.utmMedium = params['utm_medium'] : this.utm.utmMedium = this.utm.utmMedium
        params['utm_content'] != null ? this.utm.utmContent = params['utm_content'] : this.utm.utmContent = this.utm.utmContent
        this.$store.commit('parameters', this.utm)
      }
    },
    mounted(){
      this.param()
    }
  }
</script>
<style lang="scss">
#app {
  @apply font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}
sub{
  font-size: 0.6em;
}
</style>
