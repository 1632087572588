import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    utm: {},
    form: {}
  },
  mutations: {
    parameters (state, payload){
      state.utm = payload
    },
    setForm (state, payload){
      state.form = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
