<template>
  <div class="stellantis flex justify-center items-center h-screen">
    <img src="img/logo/STELLANTIS-and-you-logo-blue.png" alt="" class="w-64 mx-auto">
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Footer,
  },
}
</script>
